.mt-n13 {
  margin-top: -52px;
}

.mt-n14 {
  margin-top: -56px;
}

.mt-n15 {
  margin-top: -60px;
}

.mt-n16 {
  margin-top: -64px;
}

.mt-n17 {
  margin-top: -68px;
}

.mt-n18 {
  margin-top: -72px;
}

.mt-n19 {
  margin-top: -76px;
}

.mt-n20 {
  margin-top: -80px;
}

.mt-n21 {
  margin-top: -84px;
}

.mt-n22 {
  margin-top: -88px;
}

.mt-n23 {
  margin-top: -92px;
}

.mt-n24 {
  margin-top: -96px;
}

.mt-n25 {
  margin-top: -100px;
}

.mt-n26 {
  margin-top: -104px;
}

.mt-n27 {
  margin-top: -108px;
}

.v-tabs.titlecase--v-tab .v-tab {
  text-transform: capitalize !important;
}

.v-data-table--dense.medium-dense td {
  height: 34px !important;
}

.v-expansion-panels {
  z-index: 0 !important;
}

.stripped .v-input__control {
  min-height: 0 !important;
}

.stripped .v-input__control .v-select__selections {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}

.stripped .v-input__control .v-select__selections .v-select__selection {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
  overflow: unset !important;
}

.stripped .v-input__control .v-select__selections input {
  display: none;
}

.dense .v-expansion-panel--active .v-expansion-panel-header {
  min-height: 48px !important;
  border-bottom: 1px solid var(--v-dimgrey-base);
  border-width: thin;
}

.dense .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 24px 8px !important;
}

.has-border-all {
  border: 1px solid var(--v-dimgrey-base);
}

.has-border-bottom {
  border-bottom: 1px solid var(--v-dimgrey-base);
}

.rounded {
  border-radius: 8px !important;
}

.helper {
  border: 1px solid yellow;
}

.border-dashed {
  border: 1.5px dashed var(--v-lightordark-base) !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-none {
  user-select: none;
}

.pointer:hover {
  cursor: pointer;
}

input[type="text"] {
  outline: none;
}

.td--no-hover:hover {
  background: inherit !important;
}

.toolbar--no-padding .v-toolbar__content {
  padding: 0 !important;
}

.d-grid {
  display: grid;
}

* {
  font-family: "Roboto", sans-serif;
}

.v-toolbar {
  z-index: 1 !important;
}

.v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile {
  font-size: 0.95rem;
  font-weight: 400;
}

.v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile--active {
  font-weight: 500;
}

.v-label {
  font-size: 1rem !important;
}

.v-tabs__item {
  text-transform: capitalize;
}

.v-tabs__container {
  border-bottom: 1px solid #f3f3f3;
}

a,
a:hover {
  text-decoration: none !important;
}

.v-datatable thead th.column.sortable:hover {
  color: rgba(236, 236, 236, 0.87) !important;
}

.v-datatable.v-table tr {
  cursor: pointer;
}

.v-datatable.v-table thead {
  background: #f6f9fc;
  font-size: 0.65rem;
  letter-spacing: 1px;
  text-transform: capitalize;
  border-bottom: 1px solid #e9ecef;
}

.v-datatable.v-table thead tr:first-child {
  height: 20px;
  border-top: 1px solid #efefef;
  border-bottom: unset;
}

.v-datatable.v-table thead tr:first-child th {
  padding: 10px 24px;
  color: #333;
  white-space: nowrap !important;
}

.v-datatable.v-table thead tr:first-child th span {
  white-space: nowrap !important;
}

.v-datatable.v-table thead tr:first-child th.active {
  color: #333 !important;
}

.v-datatable.v-table thead tr:first-child th.active * {
  color: #333 !important;
}

thead th {
  white-space: nowrap !important;
}

.lightbox {
  box-shadow: 0 0 10px inset rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 80px);
}

.v-alert {
  border: none;
  border-radius: 5px;
}

.v-alert .v-icon.mdi {
  color: white !important;
}

.v-input.required label.v-label:before {
  content: "*";
  color: red;
  margin-right: 5px;
}

.required label:before {
  content: "*";
  color: red;
  margin-right: 5px;
}

/* ::-webkit-scrollbar {
    height: 16px;
    overflow: visible;
    width: 16px;
}
::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}
::-webkit-scrollbar-corner {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px 1px 1px 6px;
    min-height: 28px;
    padding: 100px 0 0;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
        inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
        inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
} */

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-list__group.v-list__group--active {
  border-left: 2px solid var(--v-primary-base);
}

.offset-t-1 {
  top: -4px !important;
  position: relative;
}

.offset-b-1 {
  bottom: 4px !important;
  position: relative;
}

.offset-l-1 {
  left: -4px !important;
  position: relative;
}

.offset-r-1 {
  right: 4px !important;
  position: relative;
}

.offset-t-2 {
  top: -8px !important;
  position: relative;
}

.offset-b-2 {
  bottom: 8px !important;
  position: relative;
}

.offset-l-2 {
  left: -8px !important;
  position: relative;
}

.offset-r-2 {
  right: 8px !important;
  position: relative;
}

.offset-t-3 {
  top: -16px !important;
  position: relative;
}

.offset-b-3 {
  bottom: 16px !important;
  position: relative;
}

.offset-l-3 {
  left: -16px !important;
  position: relative;
}

.offset-r-3 {
  right: 16px !important;
  position: relative;
}

.offset-t-4 {
  top: -24px !important;
  position: relative;
}

.offset-b-4 {
  bottom: 24px !important;
  position: relative;
}

.offset-l-4 {
  left: -24px !important;
  position: relative;
}

.offset-r-4 {
  right: 24px !important;
  position: relative;
}

.offset-t-5 {
  top: -48px !important;
  position: relative;
}

.offset-b-5 {
  bottom: 48px !important;
  position: relative;
}

.offset-l-5 {
  left: -48px !important;
  position: relative;
}

.offset-r-5 {
  right: 48px !important;
  position: relative;
}

.offset-t-6 {
  top: -64px !important;
  position: relative;
}

.offset-b-6 {
  bottom: 64px !important;
  position: relative;
}

.offset-l-6 {
  left: -64px !important;
  position: relative;
}

.offset-r-6 {
  right: 64px !important;
  position: relative;
}

.offset-t-7 {
  top: -80px !important;
  position: relative;
}

.offset-b-7 {
  bottom: 80px !important;
  position: relative;
}

.offset-l-7 {
  left: -80px !important;
  position: relative;
}

.offset-r-7 {
  right: 80px !important;
  position: relative;
}

.offset-t-8 {
  top: -96px !important;
  position: relative;
}

.offset-b-8 {
  bottom: 96px !important;
  position: relative;
}

.offset-l-8 {
  left: -96px !important;
  position: relative;
}

.offset-r-8 {
  right: 96px !important;
  position: relative;
}

.context-menu {
  box-shadow: unset !important;
}

/* Transitions */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.v-calendar-monthly {
  border-left: 1px solid #9e9e9e;
  border-top: 1px solid #9e9e9e;
}

.tab-justify-left .v-tab {
  justify-content: left !important;
}

.coverphoto.v-image .v-image__image {
  background-position: 0 -400px !important;
}

@media only screen and (max-width: 755px) {
  .coverphoto.v-image .v-image__image {
    background-position: 0 -30px !important;
  }
}

.v-menu__content {
  min-width: auto !important;
}

.nowrap {
  white-space: nowrap !important;
}

.v-stepper__header {
  height: 50px !important;
}

.v-stepper__header .v-stepper__step {
  padding: 0 10px !important;
}

.v-list > .v-list-item .v-list-item__icon {
  margin-left: 8px !important;
}

.v-data-table.no-hover tbody tr:hover:not(.v-datatable__expand-row) {
  background: unset !important;
}

tr.no-hover:hover {
  background: unset !important;
}

.row {
  margin: 0px;
}

.fill-width {
  width: 100%;
}

