.has-border-all {
    border: 1px solid var(--v-dimgrey-base);
}
.has-border-bottom {
    border-bottom: 1px solid var(--v-dimgrey-base);
}

.rounded {
    border-radius: 8px !important;
}

.helper {
    border: 1px solid yellow;
}

.border-dashed {
    border: 1.5px dashed var(--v-lightordark-base) !important;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-none {
    user-select: none;
}

.pointer:hover {
    cursor: pointer;
}

input[type="text"] {
    outline: none;
}

.td--no-hover:hover {
    background: inherit !important;
}

.toolbar--no-padding .v-toolbar__content {
    padding: 0 !important;
}

.d-grid {
    display: grid;
}
