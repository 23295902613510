.mt-n13 {
    margin-top: -52px;
}
.mt-n14 {
    margin-top: -56px;
}
.mt-n15 {
    margin-top: -60px;
}
.mt-n16 {
    margin-top: -64px;
}
.mt-n17 {
    margin-top: -68px;
}
.mt-n18 {
    margin-top: -72px;
}
.mt-n19 {
    margin-top: -76px;
}
.mt-n20 {
    margin-top: -80px;
}
.mt-n21 {
    margin-top: -84px;
}
.mt-n22 {
    margin-top: -88px;
}
.mt-n23 {
    margin-top: -92px;
}
.mt-n24 {
    margin-top: -96px;
}
.mt-n25 {
    margin-top: -100px;
}
.mt-n26 {
    margin-top: -104px;
}
.mt-n27 {
    margin-top: -108px;
}

.v-tabs.titlecase--v-tab .v-tab {
    text-transform: capitalize !important;
}

.v-data-table--dense.medium-dense td {
    height: 34px !important;
}

.v-expansion-panels {
    z-index: 0 !important;
}

.stripped .v-input__control {
    min-height: 0 !important;
    .v-select__selections {
        font-size: 1.5rem !important;
        font-weight: 400;
        letter-spacing: normal !important;

        .v-select__selection {
            font-size: 1.5rem !important;
            font-weight: 400 !important;
            letter-spacing: normal !important;
            overflow: unset !important;
        }

        input {
            display: none;
        }
    }
}

.dense {
    .v-expansion-panel--active .v-expansion-panel-header {
        min-height: 48px !important;
        border-bottom: 1px solid var(--v-dimgrey-base);
        border-width: thin;
    }

    .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 24px 8px !important;
    }
}
