@import "./derived.scss";
@import "./helpers.scss";

* {
    font-family: "Roboto", sans-serif;
}

.v-toolbar {
    z-index: 1 !important;
}

.v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile {
    font-size: 0.95rem;
    font-weight: 400;
    &--active {
        font-weight: 500;
    }
}
.v-label {
    font-size: 1rem !important;
}
.v-tabs__item {
    text-transform: capitalize;
}
.v-tabs__container {
    border-bottom: 1px solid #f3f3f3;
}
a,
a:hover {
    text-decoration: none !important;
}

.v-datatable thead th.column.sortable:hover {
    color: rgba(236, 236, 236, 0.87) !important;
}

.v-datatable.v-table {
    tr {
        cursor: pointer;
    }

    thead {
        background: #f6f9fc;
        // background: var(--v-primary-base);
        font-size: 0.65rem;
        letter-spacing: 1px;
        text-transform: capitalize;
        border-bottom: 1px solid #e9ecef;

        tr:first-child {
            height: 20px;
            border-top: 1px solid #efefef;
            border-bottom: unset;

            th {
                padding: 10px 24px;
                color: #333;
                white-space: nowrap !important;
                span {
                    white-space: nowrap !important;
                }

                &.active {
                    color: #333 !important;

                    * {
                        color: #333 !important;
                    }
                }
            }
        }
    }
}

thead {
    th {
        white-space: nowrap !important;
    }
}

.lightbox {
    box-shadow: 0 0 10px inset rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6) 0%,
        transparent 80px
    );
}
.v-alert {
    border: none;
    border-radius: 5px;
    .v-icon.mdi {
        color: white !important;
    }
}
//
.v-input.required {
    label.v-label:before {
        content: "*";
        color: red;
        margin-right: 5px;
    }
}
.required label:before {
    content: "*";
    color: red;
    margin-right: 5px;
}

/* ::-webkit-scrollbar {
    height: 16px;
    overflow: visible;
    width: 16px;
}
::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}
::-webkit-scrollbar-corner {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px 1px 1px 6px;
    min-height: 28px;
    padding: 100px 0 0;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
        inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
        inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
} */
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: var(--v-secondary-base);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.v-list__group.v-list__group--active {
    border-left: 2px solid var(--v-primary-base);
}

$spcr: 16px;
$spcrs: (
    1: $spcr * 0.25,
    2: $spcr * 0.5,
    3: $spcr,
    4: $spcr * 1.5,
    5: $spcr * 3,
    6: $spcr * 4,
    7: $spcr * 5,
    8: $spcr * 6
);

@each $i, $v in $spcrs {
    .offset-t-#{$i} {
        top: ($v * -1) !important;
        position: relative;
    }

    .offset-b-#{$i} {
        bottom: $v !important;
        position: relative;
    }

    .offset-l-#{$i} {
        left: ($v * -1) !important;
        position: relative;
    }

    .offset-r-#{$i} {
        right: $v !important;
        position: relative;
    }
}

.context-menu {
    box-shadow: unset !important;
}

/* Transitions */

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.2s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.v-calendar-monthly {
    border-left: 1px solid #9e9e9e;
    border-top: 1px solid #9e9e9e;
}

.tab-justify-left .v-tab {
    justify-content: left !important;
}

.coverphoto.v-image .v-image__image {
    background-position: 0 -400px !important;
}

@media only screen and (max-width: 755px) {
    .coverphoto.v-image .v-image__image {
        background-position: 0 -30px !important;
    }
}

.v-menu__content {
    min-width: auto !important;
}

.nowrap {
    white-space: nowrap !important;
}

.v-stepper__header {
    height: 50px !important;

    .v-stepper__step {
        padding: 0 10px !important;
    }
}

.v-list {
    > .v-list-item {
        .v-list-item__icon {
            margin-left: 8px !important;
        }
    }
}

.v-data-table.no-hover {
    tbody tr:hover:not(.v-datatable__expand-row) {
        background: unset !important;
    }
}

tr.no-hover:hover {
    background: unset !important;
}

.row {
    margin: 0px;
}

.fill-width {
    width: 100%;
}
